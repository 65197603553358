var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.taskSetData.started)?_c('div',[_c('CCard',{staticClass:"mt-3"},[_c('CCardHeader',{staticClass:"h4"},[_vm._v("Submission "),_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.submissionInfo.source_code),expression:"submissionInfo.source_code"}],staticClass:"text-success",attrs:{"name":"cil-check-circle"}})],1),_c('CCardBody',[_c('p',[_vm._v(" Filename: "),_c('code',{staticClass:"h3"},[_vm._v(_vm._s(_vm.taskInfo.name))])]),_c('p',[_vm._v("Max point: "+_vm._s(_vm.taskInfo.max_points))]),_c('p',[_vm._v(" Status: "),_c('CBadge',{staticClass:"ml-1",attrs:{"color":_vm.submissionInfo.source_code ? 'success' : 'danger'}},[_vm._v(_vm._s(_vm.submissionInfo.source_code ? "Submitted" : "No Submission"))])],1),_c('p',[_vm._v(" Late: "),_c('CBadge',{staticClass:"ml-1",attrs:{"color":_vm.submissionInfo.late === null
              ? 'secondary'
              : _vm.submissionInfo.late
              ? 'danger'
              : 'success'}},[_vm._v(_vm._s(_vm.submissionInfo.late === null ? "N/A" : _vm.submissionInfo.late ? "Yes" : "No"))])],1),_c('p',[_vm._v("Submitted at: "+_vm._s(_vm.formatDate(_vm.submissionInfo.submitted_at)))]),_c('CForm',[_c('CTextarea',{attrs:{"label":"Latest Submission","rows":"9","value":_vm.code,"readonly":""}}),(!_vm.taskSetData.ended)?_c('CInputFile',{ref:"upload_box",attrs:{"label":"New Submission","description":"Max size 1MB","accept":".txt,.py"},on:{"change":_vm.checkFile}}):_vm._e()],1)],1)],1),(_vm.taskInfo.autograded)?_c('CCard',[_c('CCardHeader',{staticClass:"h4"},[_vm._v("Submission Verification "),_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.submissionInfo.verified),expression:"submissionInfo.verified"}],staticClass:"text-success",attrs:{"name":"cil-check-circle"}})],1),_c('CCardBody',[_c('p',[_vm._v(" Passed: "),_c('CBadge',{attrs:{"color":_vm.submissionInfo.verified ? 'success' : 'danger'}},[_vm._v(_vm._s(_vm.submissionInfo.verified ? "Yes" : "No"))])],1),_c('CTextarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.submissionInfo.verified),expression:"!submissionInfo.verified"}],attrs:{"label":"Details","rows":"3","readonly":"","value":_vm.verifyResult}}),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.submissionInfo.verified &&
          (!_vm.submissionInfo.verify_result ||
            _vm.submissionInfo.verify_result.length === 0)
        ),expression:"\n          !submissionInfo.verified &&\n          (!submissionInfo.verify_result ||\n            submissionInfo.verify_result.length === 0)\n        "}],ref:"verifyButton",attrs:{"color":"primary"},on:{"click":_vm.verify}},[_vm._v(" Verify ")])],1)],1):_vm._e(),_c('CModal',{attrs:{"show":_vm.showWarning,"centered":true,"title":"Warning","color":"warning"},on:{"update:show":function($event){_vm.showWarning=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.showWarning = false}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"warning"},on:{"click":_vm.confirm}},[_vm._v("OK")])]},proxy:true}],null,false,3743465240)},[_vm._v(" By clicking submit, you hereby declare that you are the sole author of the work. If plagiarism is detected, you will not receive any credit for your work and further disciplinary actions. ")]),_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.successToasts),function(toast){return [_c('CToast',{key:'toast' + toast,attrs:{"show":true,"color":"success","header":"Upload Success"}},[_vm._v(" Upload code successfully. ")])]}),_vm._l((_vm.errorToasts),function(toast){return [_c('CToast',{key:'toast' + toast,attrs:{"show":true,"color":"danger","header":"Upload Error"}},[_vm._v(" Failed to upload code. ")])]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }