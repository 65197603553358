<template>
  <div v-if="taskSetData.started">
    <CCard class="mt-3">
      <CCardHeader class="h4"
        >Submission
        <CIcon
          v-show="submissionInfo.source_code"
          class="text-success"
          name="cil-check-circle"
      /></CCardHeader>
      <CCardBody>
        <p>
          Filename: <code class="h3">{{ taskInfo.name }}</code>
        </p>
        <p>Max point: {{ taskInfo.max_points }}</p>
        <p>
          Status:
          <CBadge
            :color="submissionInfo.source_code ? 'success' : 'danger'"
            class="ml-1"
            >{{
              submissionInfo.source_code ? "Submitted" : "No Submission"
            }}</CBadge
          >
        </p>
        <p>
          Late:
          <CBadge
            :color="
              submissionInfo.late === null
                ? 'secondary'
                : submissionInfo.late
                ? 'danger'
                : 'success'
            "
            class="ml-1"
            >{{
              submissionInfo.late === null
                ? "N/A"
                : submissionInfo.late
                ? "Yes"
                : "No"
            }}</CBadge
          >
        </p>
        <p>Submitted at: {{ formatDate(submissionInfo.submitted_at) }}</p>
        <CForm>
          <CTextarea
            label="Latest Submission"
            rows="9"
            :value="code"
            readonly
          />
          <CInputFile
            ref="upload_box"
            label="New Submission"
            description="Max size 1MB"
            accept=".txt,.py"
            @change="checkFile"
            v-if="!taskSetData.ended"
          />
        </CForm>
      </CCardBody>
    </CCard>
    <CCard v-if="taskInfo.autograded">
      <CCardHeader class="h4"
        >Submission Verification
        <CIcon
          v-show="submissionInfo.verified"
          class="text-success"
          name="cil-check-circle"
      /></CCardHeader>
      <CCardBody>
        <p>
          Passed:
          <CBadge :color="submissionInfo.verified ? 'success' : 'danger'">{{
            submissionInfo.verified ? "Yes" : "No"
          }}</CBadge>
        </p>
        <CTextarea
          label="Details"
          rows="3"
          readonly
          :value="verifyResult"
          v-show="!submissionInfo.verified"
        ></CTextarea>
        <CButton
          ref="verifyButton"
          color="primary"
          @click="verify"
          v-show="
            !submissionInfo.verified &&
            (!submissionInfo.verify_result ||
              submissionInfo.verify_result.length === 0)
          "
        >
          Verify
        </CButton>
      </CCardBody>
    </CCard>
    <CModal
      :show.sync="showWarning"
      :centered="true"
      title="Warning"
      color="warning"
    >
      By clicking submit, you hereby declare that you are the sole author of the
      work. If plagiarism is detected, you will not receive any credit for your
      work and further disciplinary actions.
      <template #footer>
        <CButton @click="showWarning = false" color="secondary">Cancel</CButton>
        <CButton @click="confirm" color="warning">OK</CButton>
      </template>
    </CModal>
    <CToaster :autohide="3000">
      <template v-for="toast in successToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          color="success"
          header="Upload Success"
        >
          Upload code successfully.
        </CToast>
      </template>
      <template v-for="toast in errorToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          color="danger"
          header="Upload Error"
        >
          Failed to upload code.
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import _formatDate from "@/misc/dateutils.js";

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export default {
  props: {
    taskSet: String,
    taskData: Object,
    taskSetData: Object,
    submissionData: Object,
  },
  data() {
    return {
      showWarning: false,
      files: [],
      uploadb: null,
      successToasts: 0,
      errorToasts: 0,
    };
  },
  methods: {
    checkFile(files, evt) {
      if (files.length > 0 && files[0].size > 1024 * 1024) {
        alert("File size has exceeded the limit.");
        evt.target.value = "";
      }
      if (files.length > 0) {
        this.files = files;
        this.showWarning = true;
        this.uploadb = evt.target;
      }
    },
    confirm() {
      this.showWarning = false;
      this.performUpload();
    },
    async performUpload() {
      if (this.files.length > 0) {
        const sol = await readFileAsync(this.files[0]);

        const data = {
          task: this.taskInfo.id,
          task_set: this.taskSet,
          source_code: sol,
        };
        try {
          const res = await this.axios.post(
            `/assignment/taskset/${this.taskSet}/submission/`,
            data
          );
          if (res.status == 200) {
            this.successToasts++;
          } else {
            throw "Failed";
          }
        } catch (error) {
          this.errorToasts++;
        } finally {
          this.$emit("refresh");
          this.uploadb.value = "";
        }
      }
    },
    async verify() {
      this.$refs.verifyButton.disabled = true;
      const res = await this.axios.post(
        `/assignment/verify/${this.submissionInfo.id}/`,
        {}
      );
      this.$refs.verifyButton.disabled = false;
      this.$emit("refresh");
    },
    formatDate(d) {
      return _formatDate(d);
    },
  },
  computed: {
    taskInfo() {
      return this.taskData;
    },
    submissionInfo() {
      return this.submissionData;
    },
    code() {
      if (
        "source_code" in this.submissionInfo &&
        this.submissionInfo.source_code
      ) {
        return this.submissionInfo.source_code;
      } else {
        return "# No Submission";
      }
    },
    verifyResult() {
      if (this.submissionInfo && this.submissionInfo.verify_result) {
        const r = JSON.parse(this.submissionInfo.verify_result);
        let output = "";
        r.tests.map((item, idx) => {
          output += `Test ${idx}: ` + item.error_info;
        });
        return output;
      } else {
        return "";
      }
    },
  },
};
</script>