<template>
  <CCard>
    <CCardHeader class="h4">Feedback</CCardHeader>
    <CCardBody>
      <CInput label="Score" :value="score" readonly />
      <CTextarea label="Grader Feedback" rows="3" :value="feedback" readonly />
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  props: {
    submissionData: Object,
  },
  computed: {
    score() {
      return this.submissionData.points;
    },
    feedback() {
      return this.submissionData.feedback;
    },
  },
};
</script>
