import moment from 'moment';

function formatDate(dateStr) {
    if (dateStr === null) return 'N/A'
    try {
        const d = moment(dateStr);
        return d.format('lll');
    } catch (error) {
        return 'N/A';
    }
}

export default formatDate;