<template>
  <div>
    <CCard>
      <CCardHeader>Assignment {{ assnId }} </CCardHeader>
      <CCardBody>
        <CRow class="mb-3">
          <CCol sm="6">
            <CInput
              label="Start"
              horizontal
              readonly
              :value="formatDate(taskSetData.start)"
            ></CInput>
            <CInput
              label="End"
              horizontal
              readonly
              :value="formatDate(taskSetData.late)"
            ></CInput>
            <CInput
              label="Available Until"
              horizontal
              readonly
              :value="formatDate(taskSetData.end)"
            ></CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <!-- <CButtonGroup> -->
            <CButton
              v-for="(t, idx) in tasks"
              :color="getColor(t)"
              :key="`${idx}-${updateButtons}`"
              @click="setActive(idx)"
              variant="outline"
              class="mx-1"
            >
              {{ t.name }}
            </CButton>
            <!-- </CButtonGroup> -->
            <task
              :task-set="assnId"
              :task-set-data="taskSetData"
              :task-data="taskData"
              :submission-data="submission"
              @refresh="fetchSubmission"
            />
            <feedback
              :submission-data="submission"
              v-show="taskSetData.feedback_published"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import _formatDate from "@/misc/dateutils.js";
import Task from "@/components/Task";
import Feedback from "@/components/Feedback";

export default {
  props: {
    assnId: String,
  },
  components: {
    Task,
    Feedback,
  },
  data() {
    return {
      tasks: [],
      updateButtons: 0,
      activeTask: 0,
      taskData: {},
      taskSetData: {},
      submission: {},
    };
  },
  async mounted() {
    await this.fetchAssignment();
    this.setActive(this.activeTask);
  },
  methods: {
    async fetchAssignment() {
      try {
        const res = await this.axios.get(`/assignment/taskset/${this.assnId}/`);
        if (res.status === 200) {
          this.taskSetData = res.data;
          this.tasks = res.data.tasks;
        } else {
          throw "NotFound";
        }
      } catch (error) {
        this.$router.push("/NotFound");
      }
    },
    async fetchTask() {
      const taskId = this.tasks[this.activeTask].code;
      const res = await this.axios.get(`/assignment/task/${taskId}/`);
      this.taskData = res.data;
    },
    async fetchSubmission() {
      const taskId = this.tasks[this.activeTask].code;
      try {
        const res = await this.axios.get(
          `/assignment/taskset/${this.assnId}/submission/${taskId}/`
        );
        this.submission = res.data;
        // refresh task status
        await this.fetchAssignment();
        this.updateButtons++;
      } catch (error) {
        if (error.response.status === 404) {
          this.submission = {
            source_code: null,
            submitted_at: null,
            late: null,
            feedback: "No submission",
            points: 0,
            verified: false,
          };
        }
      }
    },
    setActive(idx) {
      this.activeTask = idx;
      this.fetchTask();
      this.fetchSubmission();
    },
    getColor(task) {
      if (task.status === "verified") return "success";
      else if (task.status === "submitted") return "warning";
      else return "danger";
    },
    formatDate(d) {
      return _formatDate(d);
    },
  },
  watch: {
    async assnId() {
      await this.fetchAssignment();
      this.activeTask = 0;
      this.setActive(this.activeTask);
    },
  },
};
</script>